@tailwind base;
@tailwind components;
@tailwind utilities;

.Visual {
    background-image : url('../../public/images/visuel-principal.png');
    background-position: right bottom;
    background-size: 30%;
    background-repeat: no-repeat;
}

ion-content.IonContent--neutral {
    --ion-background-color: var(--neutral);
}

ion-content.IonContent--neutral ion-item {
    --ion-item-background: transparent;
}

.Switch ion-segment-button {
    --color-checked: white;
    --indicator-color: var(--ion-color-primary);
}

ion-range {
    --bar-background: var(--ion-color-secondary);
    --knob-handle-size: 50px;
    --knob-size: 40px;
    --bar-height: 5px;
}

ion-toolbar {
    --color: white;
}

ion-back-button {
    --color: white;
}

ion-menu-button {
    --color: white;
}

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v29-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v29-latin-regular.woff') format('woff'); /* Modern Browsers */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/roboto-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-medium-webfont.woff2') format('woff'); /* Modern Browsers */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v29-latin-700.woff') format('woff'); /* Modern Browsers */
}

.firebase-emulator-warning {
    @apply hidden;
}


ion-modal.small {
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
}

ion-modal.small ion-datetime {
    height: 382px;
}

@screen lg {
    ion-modal.big {
        --width: 90%;
        --height: 80%;
    }
}



